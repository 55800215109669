﻿@import "FontAwesome/less/icons.less";
@import "FontAwesome/less/variables.less";
@fa-css-prefix: icon;
.@{fa-css-prefix}-hash {
	-ms-transform: rotate(19deg);
	-webkit-transform: rotate(19deg);
	transform: rotate(19deg);
}

.@{fa-css-prefix}-hash:before {
	content: @fa-var-slack;
}

.@{fa-css-prefix}-single:extend(.icon-hash all) { 
}
.@{fa-css-prefix}-date:extend(.icon-calendar all) { 
}
.@{fa-css-prefix}-contact:extend(.icon-user all) { 
}
.@{fa-css-prefix}-enumeration:extend(.icon-list all) { 
}
.@{fa-css-prefix}-reference:extend(.icon-link all) { 
}
.@{fa-css-prefix}-public:extend(.icon-unlock-alt all) { 
}
.@{fa-css-prefix}-private:extend(.icon-lock all) { 
}
.@{fa-css-prefix}-ok:extend(.icon-check all) { 
}